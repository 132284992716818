import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { constants } from '../../constants';
import { CustomSnackbarService } from '../pages/shared/custom-snackbar/custom-snackbar.service';
import { AlertService } from '../pages/shared/alert/alert.service';
import { state } from '@angular/animations';

@Injectable({
  providedIn: 'root'
})
export class DataResolverService implements Resolve<any> {

  constructor(public service: ConfigService, private route: Router, public snackbarService: CustomSnackbarService, public alert: AlertService) {
  }

  async resolve(route: ActivatedRouteSnapshot) {
    const response: any = await this.service.getApi('/api/auth/profile', {}, '', 'auth_service');
    if (response.status && response.data) {
      localStorage.setItem('permissions', JSON.stringify(response.data?.permissions?.formatted_permissions));
      this.service.setPermissions();
      console.log(response.data);

      this.service.profileDetail.profilepic = response.data?.picture;
      this.service.profileDetail.client_name = response.data?.account_detail?.client_name
      this.service.profileDetail.email = response.data?.email
      this.service.profileDetail.phone = response.data?.phone;
      this.service.profileDetail.id = response.data?.id;
      this.service.profileDetail.timezone = response.data?.timezone;
      this.service.profileDetail.client_id = response.data?.account_detail?.id
      this.service.profileDetail.client_from = response.data?.name
      this.service.profileDetail.client_from_id = response.data?.account_detail?.id
      this.service.profileDetail.company_name = response.data?.account_detail?.company_name
      this.service.profileDetail.req_from_address = response?.data.account_detail?.address
      this.service.profileDetail.city = response.data?.account_detail?.city
      this.service.profileDetail.state = response.data?.account_detail?.state
      this.service.profileDetail.zip_code = response.data?.account_detail?.zip_code
      this.service.profileDetail.country = response.data?.account_detail?.country
      this.service.profileDetail.account_type = response.data?.account_type
      this.service.profileDetail.default_client = response.data?.default_client
      this.service.profileDetail.default_warehouse = response.data?.default_warehouse
      this.service.profileDetail.default_warehouse = response.data?.default_warehouse
      this.service.profileDetail.sub_client_id = response.data?.sub_client_id?.length ===1 ? response.data?.sub_client_id[0] : ''
      this.service.profileDetail.warehouse_id = response.data?.warehouse_id?.length ===1 ? response.data?.warehouse_id[0] : ''
      this.service.profileDetail.name = response.data?.name
      this.service.profileDetail.user_id = response.data?.account_detail?.user_id;
      this.service.profileDetail.service_provider_id = response.data?.service_provider_id;
      this.service.profileDetail.check_service_provider =
        response.data?.check_service_provider;
      this.service.setProfile(response.data?.picture);
      this.service.profileDetail.userRole = response.data?.permissions?.role_name
      this.service.profileDetail.service_provider_id = response.data?.permissions?.service_provider_id
      if (response.data.account_type === "service_provider") {
        this.service.accountTypeAPI = constants.serviceProviderAPI
      }
      else if (response.data.account_type === "client") {
        this.service.accountTypeAPI = constants.clientServiceAPI
      }
      else if (response.data.account_type === "admin") {
        this.service.accountTypeAPI = constants.adminAPI
      }
      //  console.log(this.service)

    } else {
      if (!response.status) {
        this.alert.show(response.message, 'success');
      }
      this.route.navigate(['login']);
    }
    return response;
  }


}
