import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, Observable, startWith } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { AlertService } from '../alert/alert.service';
import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';
import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray, CdkDragMove } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-grid-columnfilter',
  templateUrl: './grid-columnfilter.component.html',
  styleUrls: ['./grid-columnfilter.component.scss'],
})
export class GridColumnfilterComponent implements OnInit {

  @Output('RefreshGridColumn') refreshGridColumn = new EventEmitter<any>();

  selection: any;
  master_columns: any[] = [];
  master_columns_unchnage: any[] = [];

  dragStartEvent: any;
  scrollSpeed: number = 5; // Adjust this value to control scroll speed

  constructor(
    public dialogRef: MatDialogRef<GridColumnfilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = []
  ) { }

  ngOnInit(): void {
    this.selection = new SelectionModel(true, []);
    this.master_columns = this.data.data;
    this.master_columns_unchnage = [...this.data.data];
  }

  rowToogle(event: any, row: any) {
    let rowInd = this.master_columns.findIndex(x => x.displayName == row.displayName);
    if (rowInd !== -1) {
      this.master_columns[rowInd].isChanged = true;
    }
  }

  submit() {
    this.dialogRef.close(this.master_columns);
  }

  clear() {
    this.master_columns = [...this.master_columns_unchnage];
    this.dialogRef.close();
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.master_columns, event.previousIndex, event.currentIndex);
  }

  onDragStarted(event: any) {
    this.dragStartEvent = event;
  }

  onDragMoved(event: CdkDragMove<any>) {
    const container = event.source.dropContainer.element.nativeElement;
    const dragItem = event.source.element.nativeElement;

    // Get the height of the container and the drag item
    const containerHeight = container.offsetHeight;
    const dragItemHeight = dragItem.offsetHeight;

    // Get the scroll position of the container
    const scrollPosition = container.scrollTop;

    // Get the distance from the top of the container
    const containerTop = container.getBoundingClientRect().top;

    // Get the distance from the top of the dragged item
    const dragItemTop = dragItem.getBoundingClientRect().top;

    // Scroll speed when dragging near the edges
    const scrollSpeed = 2; // Adjust this to make scrolling slower or faster

    // Threshold for scrolling up (how close to the top the item needs to be)
    const upwardScrollThreshold = 50; // Adjust this to change how far from the top you need to be to start scrolling upward

    // Threshold for scrolling down (how close to the bottom the item needs to be)
    const downwardScrollThreshold = 50; // Adjust this to control when scrolling downward starts

    // When dragging near the bottom of the container (bottom scroll)
    if (dragItemTop + dragItemHeight > containerHeight + containerTop - downwardScrollThreshold) {
      // Scroll down, but not beyond the bottom of the container
      if (scrollPosition + containerHeight < container.scrollHeight) {
        container.scrollTop = scrollPosition + scrollSpeed;
      }
    }

    // When dragging near the top of the container (top scroll)
    else if (dragItemTop < containerTop + upwardScrollThreshold) {
      // Scroll up only if we are not already at the top
      if (scrollPosition > 700) {
        container.scrollTop = scrollPosition - scrollSpeed;
      }
    }
  }








}
