import {  Injectable } from "@angular/core";
import { AppConstants } from "./app-constants";
import { Router } from "@angular/router";
@Injectable() export class BreadCrumbService {

 private menuNameIndex: any;
 private menuLinkIndex: any;
 private menuName: any;
 private menuLink: any;
 private breadCrumb: { label: string, route?: string, cursor?: boolean,queryParams?:{ [key: string]: any } }[] = [];

 constructor(private router: Router) {}

 setMenuNameIndex(index: number) {
  this.menuNameIndex = index;
  this.menuLinkIndex = 0;
 }
 setMenuLinkIndex(index: number) {
  this.menuLinkIndex = index
 }
 setBreadCrumb(breadcrumbs: { label: string, route?: string, cursor?: boolean,queryParams?:{ [key: string]: any } }[]) {
  this.breadCrumb = breadcrumbs;
}
getBreadCrumb() {
  return this.breadCrumb;
}
navigateTo(route: string,queryParams?: { [key: string]: any }) {
  if (route) {
    this.router.navigate([route], { queryParams: queryParams || {} }); // Properly handle query params
  }
}

clearBreadCrumb() {
  this.breadCrumb = [];
}
//  getBreadCrumb() {
//   this.menuName = AppConstants.APP_MENU_BUTTONS[this.menuNameIndex].name;
//   this.breadCrumb = this.menuName + ' ' + this.menuLinkIndex;
//  //  console.log('Breadcrumb' , this.breadCrumb);
//  }
}
