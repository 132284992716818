import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export interface Client {
  id: number | 'all';
  client_name: string;
  address: any;
  state: any;
  country: any;
  value: number;
  viewValue: string;
}
export interface GlobalFilter {
  isShow: boolean;
}

export interface Warehouse {
  id: number;
  name: string;
  value: number;
  viewValue: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  region: any;
  phone: string;
}
export interface Guide {
 step:number
}

@Injectable({ providedIn: 'root' })
export class CommonService {
  private subjectName = new Subject<any>();
  private clients$ = new BehaviorSubject<Client[] | null>(null);
  private warehouses$ = new BehaviorSubject<Warehouse[] | null>(null);
  private selectedClient$ = new Subject<Client | null>();
  private selectedWarehouse$ = new Subject<Warehouse | null>();
  private startQuide$ = new Subject<Guide | null>();
  private GlobalFilter$ = new Subject<GlobalFilter | null>();




  sendUpdate(message: string) {
    this.subjectName.next({ text: message });
    console.log('c', message);
  }

  getUpdate(): Observable<any> {
    return this.subjectName.asObservable();
  }

  setClients(clients: Client[]) {
    this.clients$.next(clients);
  }
  setGlobalFilters(isShow: GlobalFilter) {
    this.GlobalFilter$.next(isShow);
  }
  getGlobalFilters() {
    return this.GlobalFilter$.asObservable();
  }


  getClients() {
    return this.clients$;
  }

  setWarehouses(warehouses: Warehouse[]) {
    this.warehouses$.next(warehouses);
  }

  getWarehouses() {
    return this.warehouses$;
  }

  setSelectedClient(client: Client) {
    this.selectedClient$.next(client);
  }

  getSelectedClient() {
    return this.selectedClient$.asObservable();
  }

  setSelectedWarehouse(warehouse: Warehouse) {


    this.selectedWarehouse$.next(warehouse);
  }

  getSelectedWarehouse() {
    return this.selectedWarehouse$.asObservable();
  }
  setStartQuide(guide: Guide) {
    this.startQuide$.next(guide);
  }

  getStartGuide() {
    return this.startQuide$.asObservable();
  }
  downloadCSVFile(data: string, fileName: string) {
    const blob = new Blob([data], { type: 'text/csv' });
    const link = document.createElement('a');
    const date = new Date();
    link.download = `${fileName}_${date.toLocaleDateString()}.csv`;
    link.href = window.URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  downloadJSONtoCSVFile(jsonData: any[], fileName: string) {
    if (!jsonData || !jsonData.length) {
      console.error('No data available to download.');
      return;
    }

    const csvRows = [];

    const headers = Object.keys(jsonData[0]);
    csvRows.push(headers.join(',')); // Join the keys with a comma

    for (const row of jsonData) {
      const values = headers.map(header => {
        const escapedValue = (row[header] !== null && row[header] !== undefined)
          ? ('' + row[header]).replace(/"/g, '""')
          : '';
        return `"${escapedValue}"`;
      });
      csvRows.push(values.join(','));
    }

    const csvString = csvRows.join('\n');

    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    const date = new Date();
    const fileTitle = `${fileName}_${date.toLocaleDateString().replace(/\//g, '-')}.csv`;

    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', fileTitle);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  setItemToLocalStorage(key:any, data:any, updateKeys:any) {
  try {
    // Retrieve existing data from localStorage
    const existingData = localStorage.getItem(key);

    // Parse existing data if available, otherwise initialize as empty object
    let parsedData = existingData ? JSON.parse(existingData) : {};

    // Ensure updateKeys is treated as an array (convert if it's a string)
    if (typeof updateKeys === "string") {
      updateKeys = [updateKeys];
    }

    if (Array.isArray(updateKeys) && updateKeys.length > 0) {
      // Update only specified keys
      updateKeys.forEach((updateKey) => {
        if (data.hasOwnProperty(updateKey)) {
          parsedData[updateKey] = data[updateKey]; // Update specific property
        }
      });
    } else {
      // If no updateKeys are provided, merge the entire data object
      Object.assign(parsedData, data);
    }

    // Store updated data in localStorage
    localStorage.setItem(key, JSON.stringify(parsedData));
  } catch (error) {
    console.error("Error storing/updating data in localStorage", error);
  }
}



removeItemsFromLocalStorage(key:any, objKeys:any) {
  try {
    // Retrieve existing data from localStorage
    const existingData = localStorage.getItem(key);

    if (existingData) {
      let parsedData = JSON.parse(existingData);

      // Set specified keys to an empty object
      objKeys.forEach((objKey:any) => {
        if (parsedData.hasOwnProperty(objKey)) {
          parsedData[objKey] = null;
        }
      });

      // Update localStorage with modified data
      localStorage.setItem(key, JSON.stringify(parsedData));
    }
  } catch (error) {
    console.error('Error removing items from localStorage', error);
  }
}


  getLocalStorageData(key:any) {
    try {
      const storedValue = localStorage.getItem(key);
      if (storedValue) {
        return JSON.parse(storedValue);
      }
      return null;
    } catch (error) {
      console.error('Error reading data from localStorage', error);
      return null;
    }
  }

}
