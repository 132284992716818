export type RouteKeys = 'wms-job' | 'task' | 'fulfillment' | 'sales-order' | 'outbound-shipment' | 'cycle-count' | 'work-order' | 'purchase-order' | 'asn';

export function getRouteMap(id: number) {
  return new Map<RouteKeys, (string | number)[]>([
    ['wms-job', ['/operations/jobs/detail', id]],
    ['task', ['/operations/tasks/detail', id]],
    ['fulfillment', ['/fulfillment/fulfillment-requests/detail', id]],
    ['sales-order', ['/outbound/sales-orders/detail', id]],
    ['outbound-shipment', ['/outbound/outbound-shipment/detail', id]],
    ['cycle-count', ['/inventory/cycle-counts/detail', id]],
    ['work-order', ['/kitting/kit-orders/detail', id]],
    ['purchase-order', ['/inbound/purchase-order/detail', id]],
    ['asn', ['/inbound/asn/detail', id]],
  ])
}

export function getRouteName() {
  return new Map<RouteKeys, string>([
    ['wms-job', "Job ID"],
    ['task', "Task ID"],
    ['fulfillment', "FR No"],
    ['sales-order', "SO No"],
    ['cycle-count', "Cycle Count ID"],
    ['asn', "ASN ID"],
  ])
}


// getRouteMap(2).get('job')
