import {
  Component,
  ElementRef,
  OnInit,
  AfterViewChecked,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Overlay } from '@angular/cdk/overlay';
import { ConfigService } from 'src/app/services/config.service';
import { environment } from 'src/environments/environment';

interface Response {
  type: "send" | "receive";
  message: any;
  feedback?: "" | "good" | "bad";
  url?: string;
  file_name?: string;
  response_id?: number;
}

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
})

export class ChatbotComponent implements OnInit, AfterViewChecked {
  @ViewChild('scrollMe', { static: false }) private scrollContainer: ElementRef;
  userImage: any;
  handleScroll: boolean = false;
  confirmationPopup: boolean = false;
  isHover: boolean = false;
  loader: boolean = false;
  isOpen: boolean = false;
  isMaximized: boolean = false;
  icon: string = 'expand';
  public chatbotForm = { message: '' };

  messages: Response[] = [];

  commonQuestions = [
    { question: 'Generate me an inbound report' },
    { question: 'Generate me an inventory report' },
    { question: 'Generate me a shipment report' },
  ];

  constructor(private overlay: Overlay,
    public http: HttpClient,
    private service: ConfigService,
    private sanitizer: DomSanitizer
  ) {
    this.service.profile$.subscribe(profile => {
      this.userImage = profile;
    });
  }

  ngOnInit(): void {
    if (window.innerHeight <= 612) {
      this.icon = 'shrink';
    };
  }

  ngAfterViewChecked(): void {
    this.scrollToBottom();
  }

  toggleOverlay() {
    this.isOpen = !this.isOpen;
    this.isHover = !this.isHover;
    const overlayConfig = {
      hasBackdrop: false,
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .bottom('70px'),
    };
  }

  toggleMaximize() {
    this.isMaximized = !this.isMaximized;
    this.icon = this.icon === 'shrink' ? 'expand' : 'shrink';
    const chatbotContainer = document.querySelector('.chatbot-overlay-container') as HTMLElement;

    if (this.isMaximized) {
        const scale = this.calculateZoom(window.innerHeight);

        if (chatbotContainer) {
            chatbotContainer.style.transform = `scale(${scale})`;
            if (window.innerHeight > 612) {
            chatbotContainer.style.width = `50%`;
            }
        }
    } else {
        if (chatbotContainer) {
            chatbotContainer.style.transform = 'scale(1)';
            chatbotContainer.style.width = `330px`;
        }
    }
  }

  calculateZoom(windowHeight: number): number {
    let scale: number;

    if (windowHeight > 1000) {
        scale = 1.6; // 160% zoom for large screens
    } else if (windowHeight > 800) {
        scale = 1.4;
    } else if (windowHeight > 700) {
        scale = 1.3;
    } else if (windowHeight > 612) {
        scale = 1.2; // 120% zoom for medium screens
    } else  {
        scale = 0.8; // 80% zoom for screens below 612px
    }
    return scale;
  }

  private scrollToBottom(): void {
    try {
      if (this.scrollContainer && this.handleScroll) {
        const container = this.scrollContainer.nativeElement;
        container.scrollTop = container.scrollHeight;
        this.handleScroll = false;
      }
    } catch (err) {
      console.error('Error while scrolling:', err);
    }
  }

  deletePopup() {
    this.confirmationPopup = true;
  }

  deleteChat() {
    this.messages = [];
    this.confirmationPopup = !this.confirmationPopup;
  }

  submitFeedback(i: number, feedback: "good" | "bad", response_id?: number) {
    if (i > -1) {
      if (this.messages[i].feedback === feedback) {
        // If the same feedback is clicked, reset ''
        this.messages[i].feedback = "";
      } else {
        // Otherwise, set to the clicked feedback
        this.messages[i].feedback = feedback;
      }
    }

    const feedbackData = {
      feedback: this.messages[i].feedback,
      response_id: response_id
    };
    this.sendPostRequest(feedbackData);
  }

  commonQues(question: any) {
    this.chatbotForm.message = question;
    setTimeout(() => {
      const input = document.getElementById(
        'chatbot-input'
      ) as HTMLInputElement;
      input.focus();
    }, 0);
  }

  sendPostRequest(feedbackData?: { feedback?: "" | "good" | "bad", response_id?: number }) {
    const service_provider_id = this.service.profileDetail.service_provider_id;
    const access_token  = localStorage.getItem("access_token");

    const headers = {
      'Content-Type': 'application/json',
      'SHISPARE-API-Key': 'B-4x4vbpbHg0a7eYy0rhHPRBWerqZPqOWPKiKPw8XTc=',
      'Authorization': `Bearer ${access_token}`,
    };

    const cleanedMessage = this.chatbotForm.message.replace(/\n/g, '');

    const endpoint = feedbackData ? 'feedback' : 'query';
    const sendData = feedbackData ? feedbackData : { message: cleanedMessage, service_provider_id: service_provider_id };

    this.http
      .post(
        environment.chatbotUrl + endpoint,
        sendData,
        { headers }
      )
      .subscribe(
        (response: any) => {
          this.loader = false;
          
          if (feedbackData) {
            console.log('Feedback Response:', response);
          } else {
            this.messages.push({
              type: 'receive',
              message:  response.response,
              url: response.url,
              file_name: response.file_name,
              response_id: response.response_id
            });
            this.handleScroll = true;
          }
        },
        (error) => {
          if (feedbackData) {
            console.error(error);
          } else {
            this.loader = true;
            setTimeout(() => {
              this.messages.push({
                type: 'receive',
                message: 'Something went wrong',
              });
            }, 40);
            this.handleScroll = true;
            this.loader = false;
          }
        }
      );
  }

  submit() {
    if (this.chatbotForm.message.trim()) {
      this.sendPostRequest();
      this.messages.push({ type: 'send', message: this.chatbotForm.message });
      this.chatbotForm.message = '';
      this.loader = true;
    }
  }

  handleImageError(event: any): void {
    event.target.src = '../../../../assets/images/user-avatar.png';  // Set the fallback image
  }
}
