<div class="chatbot-wrapper">
    <div class="main-container">
        <div class="gif-container cursor-pointer" id="gif-container" cdkOverlayOrigin #trigger="cdkOverlayOrigin"
            (click)="toggleOverlay()"  [ngClass]="{'gif-hover': isHover}">
            <img src="assets/images/face-gif-unscreen.gif" alt="Chatbot" class="chatbot-image" />
            <p class="chatbot-button-container mt-3 pl-2"> Ask WizeHelp </p>
        </div>
    </div>

    <ng-template cdkConnectedOverlay [cdkConnectedOverlayOpen]="isOpen">
        <div class="chatbot-overlay-container d-block">

            <!-- Chatbot Header -->
            <div class="chatbot-header d-flex align-items-center justify-content-between">
                <img src="assets/images/wize-help-logo.svg" width="110" alt="Chatbot" />
                <div class="d-flex justify-content-between gap-2">
                    <img [src]="'../../../../assets/images/' + (icon === 'shrink' ? 'shrink' : 'expand') + '.png'"
                        [alt]="icon === 'shrink' ? 'shrink button' : 'expand button'" (click)="toggleMaximize()"
                        class="header-icon d-flex justify-content-center align-items-center cursor-pointer" />
                    <mat-icon class="header-icon d-flex justify-content-center align-items-center cursor-pointer"
                        (click)="deletePopup()">delete_outline</mat-icon>
                    <mat-icon class="header-icon d-flex justify-content-center align-items-center cursor-pointer"
                        (click)="toggleOverlay()">close</mat-icon>
                </div>
            </div>

            <div class="chatbot-content-wrapper d-flex justify-content-center">
                <!-- Chatbot Messages -->
                <div #scrollMe class="messages-wrapper px-3 d-flex flex-column w-100">
                    <ng-container *ngIf="messages.length > 0; else commonQuestion">
                        <ng-container *ngFor="let message of messages; let i = index">
                            <!-- Bot response -->
                            <div *ngIf="message.type === 'receive'" class="d-flex justify-content-start mb-3 gap-2">
                                <img src="assets/images/guide-logo.png" width="30" height="30" alt="Receive image" class="message-image" />
                                <div>
                                    <div class="message receive">
                                         {{message.message}}
                                        <a [href]="message.url" *ngIf="message.url" target="_blank" class="d-block">{{ message.file_name }}</a>
                                    </div>
                                    <!-- Like/Dislike buttons for bot response -->
                                    <div *ngIf="message.response_id"  class="feedback-buttons d-flex justify-content-end mt-1">
                                        <mat-icon (click)="submitFeedback(i, 'good', message.response_id)"
                                            [ngClass]="{'like': message.feedback === 'good'}">thumb_up</mat-icon>
                                        <mat-icon (click)="submitFeedback(i, 'bad', message.response_id)"
                                            [ngClass]="{'dislike': message.feedback === 'bad'}">thumb_down</mat-icon>
                                    </div>
                                </div>
                            </div>

                            <!-- User Response -->
                            <div *ngIf="message.type === 'send'" class="d-flex justify-content-end mb-3 gap-2">
                                <div class="message sent"> {{message.message}} </div>
                                <img [src]="userImage ? userImage : 'assets/images/user-avatar.png'" height="27" width="27"
                                alt="Send image" class="message-image" (error)="handleImageError($event)"/>
                            </div>
                        </ng-container>

                        <!-- Loader -->
                        <div *ngIf="loader" class="d-flex justify-content-start align-items-center mb-2">
                            <img src="assets/images/guide-logo.png" width="30" height="30" alt="Receive image"
                                class="message-image" />
                            <span class="ml-3">Fetching response</span>
                            <img src="assets/images/loader.gif" width="30" alt="Loading"/>
                        </div>
                    </ng-container>

                    <!-- Common Questions -->
                    <ng-template #commonQuestion>
                        <div class="questions-wrapper">
                            <p class="mb-3">Common questions are:</p>
                            <div *ngFor="let ques of commonQuestions" class="question cursor-pointer mb-3"
                                (click)="commonQues(ques?.question)">
                                <p class="p-3">{{ ques.question }}</p>
                            </div>
                        </div>
                    </ng-template>
                </div>

                <!-- Chatbot Footer -->
                <div class="chatbot-footer d-flex justify-content-between align-items-center">
                    <textarea type="text" id="chatbot-input" class="chatbot-input send-textarea w-75"
                        [(ngModel)]="chatbotForm.message" placeholder="Ask a question" (keyup.enter)="submit()">
                    </textarea>
                    <mat-icon class="send-icon d-flex justify-content-center align-items-center cursor-pointer"
                        [ngClass]="{ 'disabled': chatbotForm.message.trim() === '' }"
                        [attr.disabled]="chatbotForm.message.trim() === '' ? true : null" 
                        (click)="submit()">send</mat-icon>
                </div>
            </div>


            <!-- Confirmation Popup -->
            <div *ngIf="confirmationPopup" class="popup-wrapper d-flex align-items-center justify-content-center">
                <div class="popup-content">
                    <div class="d-flex justify-content-between align-items-center border-bottom mb-3 px-4 pb-2">
                        <h4 class="mb-0">Clear Chat</h4>
                        <mat-icon class="d-flex align-items-center cursor-pointer"
                            (click)="this.confirmationPopup = !this.confirmationPopup">clear</mat-icon>
                    </div>

                    <div class="row mt-4 text-center" style="padding: 3px; text-align: center;">
                        <p>Once you clear the chat, you will no longer have access to past conversations.</p>
                    </div>

                    <div class="px-4 d-flex justify-content-center gap-2">
                        <button mat-flat-button class="btn-outlined"
                            (click)="this.confirmationPopup = !this.confirmationPopup">Cancel</button>
                        <button mat-flat-button class="mr-2 btn-main" (click)="deleteChat()">Clear Chat</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>
